<template>
    <div>
        <div class="form-row w-full">
            <div class="form-col">
                <div>
                    <label for="email">Email</label>
                    <text-input
                      id="email"
                      v-model="email"
                    />
                    <span class="form-hint">Enter the email address that should receive any completed form submissions. A copy of the response will also be stored in the application.</span>
                    <span v-if="hasError('email')" class="form-error">{{ showError('email') }}</span>
                </div>
            </div>
        </div>

        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
import Widget from "@/mixins/Widget";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import TextInput from '@/components/ui/TextInput';

export default {
    mixins: [ ModalNavigation, Widget ],
    components: { ModalFooter, TextInput },
    emits: ['save-page-widget'],
    data: () => {
        return {
            email: null,
            isProcessing: false,
        }
    },
    methods: {
        save() {
            if(this.validator.passes()) {
                this.isProcessing = true;
                this.$emit('save-page-widget', { email: this.email });
            }
        },
    },
    validations: {
        'email' : 'required | email',
    },
    created() {
        this.initValidator();

        if(this.values.length) {
            this.email = this.getValue('email');
        }
    }
}
</script>
